import React from 'react';

import * as MenuAdmSRV from '../types/requests/menu.adm.srv';

import Box from '../components/Box';
import EmailInput from './EmailInput';
import LabelInput from './LabelInput';
import Button from '../components/Button';
import Spinner from '../components/Spinner';
import Fetch from '../handlers/Fetch';

import { IAppContext } from '../app-context/AppContext';

// -------

interface IProps {
  ctx: IAppContext;
}

const defaultParams: MenuAdmSRV.postMenuUserParams = {
  email: '',
  phone: '',
  nombre: '',
  apellido: '',
  tipoDoc: 'DNI',
  numeroDoc: '',
  domicilio: '',
  ciudad: 'San Martín de los Andes',
  provincia: 'Neuquén',
  pais: 'Argentina'
};

// --------

export default function UsersContent({ ctx }: IProps): React.ReactElement {

  const [loading, setLoading] = React.useState<boolean>(false);

  const [params, setParams] = React.useState<MenuAdmSRV.postMenuUserParams>(defaultParams);

  const handleChange = (field: string, value: string) => {
    setParams({
      ...params,
      [ field ]: value
    });
  };

  const postNewUser = async () => {
    setLoading(true);
    const [ error, result ] = await Fetch.post<MenuAdmSRV.postMenuUserParams, MenuAdmSRV.postMenuUserResult>(
      MenuAdmSRV.ENDPOINT.postMenuUser,
      params
    );
    if (error) {
      ctx.renderAlert({ title: error.warning, detail: error.message });
    } else if (!result || !result.userId) {
      ctx.renderAlert({ title: 'Oops, algo salió mal.', detail: 'Quizás no se haya logrado crear el usuario.' });
    } else {
      setParams(defaultParams);
    }
    setLoading(false);
  };

  if (loading) {
    return (
      <Box $wd="100%" $mg="4rem 0">
        <Spinner />
      </Box>
    );
  }

  return (
    <Box $wd="100%" $mg="4rem 0">

      <EmailInput
        email={params.email}
        onEmail={(v) => handleChange('email', v)}
      />

      <LabelInput
        label="Celular"
        value={params.phone}
        onValue={(v) => handleChange('phone', v)}
      />

      <LabelInput
        label="Nombre"
        value={params.nombre}
        onValue={(v) => handleChange('nombre', v)}
      />

      <LabelInput
        label="Apellido"
        value={params.apellido}
        onValue={(v) => handleChange('apellido', v)}
      />

      <LabelInput
        label="Tipo de Documento"
        value={params.tipoDoc}
        onValue={(v) => handleChange('tipoDoc', v)}
      />

      <LabelInput
        label="Número de Documento"
        value={params.numeroDoc}
        onValue={(v) => handleChange('numeroDoc', v)}
      />

      <LabelInput
        label="Domicilio"
        value={params.domicilio}
        onValue={(v) => handleChange('domicilio', v)}
      />

      <LabelInput
        label="Ciudad"
        value={params.ciudad}
        onValue={(v) => handleChange('ciudad', v)}
      />

      <LabelInput
        label="Provincia"
        value={params.provincia}
        onValue={(v) => handleChange('provincia', v)}
      />

      <LabelInput
        label="País"
        value={params.pais}
        onValue={(v) => handleChange('pais', v)}
      />

      <Button $wd="300px" $mgt="2rem" onClick={postNewUser}>
        Añadir usuario
      </Button>

    </Box>
  );

}
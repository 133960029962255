import React from 'react';

import * as Store from '../../types/models/store';

import Box from '../../components/Box';
import Span from '../../components/Span';
import Icons from '../../components/Icons';
import { IController } from '../../page-home/controller/types';

// -----

interface IProps {
  width: string;
  categId: string;
  product: Store.MenuProduct;
  ctl: IController;
}

// -----

export default function ProdData(props: IProps): React.ReactElement {

  const { width, categId, product, ctl } = props;
  const { title, detail, price, allowOpts, denyNotes } = product;

  const toggleObservations = () => {
    ctl.prods.setItem(categId, { ...product, denyNotes: !product.denyNotes });
  };

  const toggleOptions = () => {
    if (!product.allowOpts) ctl.menu.setEdit(product._id);
    ctl.prods.setItem(categId, { ...product, allowOpts: !product.allowOpts });
  };

  return (
    <Box $wd={width} $ver="tp" $shg="115px">

      <Box $wd="100%">
        <Box $wd="100%" $row $hor="lf" $fs="1.1rem">
          {title}
        </Box>
        <Box $wd="100%" $row $hor="lf" $fs="1.1rem" $pd="0.4rem 0">
          {`$ ${price}`}
        </Box>
        <Box $wd="100%" $row $hor="lf" $mg="0.4rem 0 0 0" $fs="0.8rem" $tc="#888">
          {detail}
        </Box>
      </Box>

      <Box $wd="100%" $row $tc="#888" $mgt="1rem">
        <Box $row $uwd="calc(50% - 0.5rem)" $pd="0.5rem" onClick={toggleObservations}>
          {
            denyNotes
            ? <Icons.EmptyBox $fs="1rem" />
            : <Icons.CheckBox $fs="1rem" />
          }
          <Span $mgl="0.2rem" $fs="0.8rem">Observaciones</Span>
        </Box>
        <Box $row $uwd="calc(50% - 0.5rem)" $pd="0.5rem"  onClick={toggleOptions}>
          {
            !allowOpts
            ? <Icons.EmptyBox $fs="1rem" />
            : <Icons.CheckBox $fs="1rem" />
          }
          <Span $mgl="0.2rem" $fs="0.8rem">Seleccionables</Span>
        </Box>
      </Box>

    </Box>
  );

}

import React from 'react';

import Box from '../../components/Box';
import Span from '../../components/Span';

import { IController } from '../../page-home/controller/types';

// -----

interface IProps {
  categId: string;
  ctl: IController;
}

// -----

export default function AddProduct({ categId, ctl }: IProps): React.ReactElement {

  return (

    <Box $wd="100%" $pd="1rem" $mg="0 0 1rem 0">

      <Span $pd="0.4rem 1rem" $rad="2rem" $bg="black" $tc="white"
        onClick={() => ctl.prods.addItem(categId)}>
        AGREGAR PRODUCTO
      </Span>

    </Box>

  );

}

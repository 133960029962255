import React from 'react';

import Box from '../components/Box';
import Label from '../components/Label';
import Input from '../components/Input';

// -------

interface IProps {
  email: string;
  showMe: boolean;
  onEmail: (s: string) => void;
  onEnter: () => void;
  disabled: boolean;
}

// -------

function EmailInput({ email, showMe, onEmail, onEnter, disabled }: IProps) {

  if (!showMe) return null;

  return (
    <Box>
      <Label $wd="100%">Email</Label>
      <Input
        id="email-input"
        $wd="280px"
        type="text"
        value={email}
        disabled={disabled}
        onEnter={onEnter}
        onChange={onEmail}
        formatValue={(v: string) => (v.toLowerCase().trim())}
        selectOnFocus
      />
    </Box>
  );

}



export default EmailInput;
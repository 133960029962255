import React from 'react';

import Box from '../components/Box';
import Label from '../components/Label';
import Input from '../components/Input';

// -------

interface IProps {
  vcode: string;
  showMe: boolean;
  onVCode: (s: string) => void;
  disabled: boolean;
  onEnter: () => void;
}

// -------

function VCodeInput({ vcode, showMe, onVCode, disabled, onEnter }: IProps) {

  if (!showMe) return null;

  return (
    <Box>
      <Label>Código</Label>
      <Input
        $wd="140px"
        $fs="2rem"
        type="text"
        value={vcode}
        disabled={disabled}
        onEnter={onEnter}
        onChange={onVCode}
        formatValue={(v: string) => {
          let aux = v.trim().slice(0, 6);
          const allowed = '0123456789';
          let formatted = '';
          for (let i=0; i < aux.length; i++) {
            if (allowed.includes(aux[i])) formatted += aux[i];
          }
          return formatted;
        }}
        selectOnFocus
      />
    </Box>
  );

}

export default VCodeInput;
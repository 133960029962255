

export const toCurrency = (num: number, currency: 'ARS' | 'USD', dec = 2): string => {
  if (!num || isNaN(num)) return '---';
  return num.toLocaleString('es-AR', {
    style: 'currency',
    currency,
    minimumFractionDigits: dec,
    maximumFractionDigits: dec
  });
}

export const toCurrencyABS = (num: number, currency: 'ARS' | 'USD', dec = 2): string => {
  return toCurrency(Math.abs(num), currency, dec);
}

export const toPercent = (num: number, dec = 2): string => {
  if (!num || isNaN(num)) return '---';
  const perc = num / 100;
  return perc.toLocaleString('es-AR', {
    style: 'percent',
    minimumFractionDigits: dec,
    maximumFractionDigits: dec
  });
}

export const toPercentABS = (num: number, dec = 2): string => {
  return toPercent(Math.abs(num), dec);
}

export const toQuantity = (num: number): string => {
  if (!num || isNaN(num)) return '---';
  return num.toFixed(0);
}

export const toQuantityABS = (num: number): string => {
  return toQuantity(Math.abs(num));
}

export const formatTimer = (ms: number): string => {
  if (!ms || isNaN(ms)) return '---';
  const totalSeconds = Math.trunc(ms / 1000);
  const minutes = Math.trunc(totalSeconds / 60);
  const seconds = totalSeconds % 60;
  if (!minutes) return `${seconds}s`;
  return `${minutes}m, ${seconds}s`;
}

export const capitalizeParrafo = (text = ''): string => {
  let cap = '';
  const lower = text.toLowerCase().trim();
  const sentences = lower.split('.');
  sentences.forEach(sent => {
    if (sent) {
      cap += ` ${sent[0].toUpperCase()}${sent.slice(1)}.`;
    }
  });
  return cap.trim();
}

export const capitalizeTitle = (text = ''): string => {
  if (!text.trim()) return '';
  const lower = text.toLowerCase().trim();
  return `${lower[0].toUpperCase()}${lower.slice(1)}`;
}

export function convertFileToBase64(file: File): Promise<any> {
  return new Promise((resolve, reject) => {
    const fileReader = new FileReader();
    fileReader.readAsDataURL(file);
    fileReader.onload = () => {
      resolve(fileReader.result);
    }
    fileReader.onerror = (error) => {
      reject(error);
    }
  });
}

export function checkAllowedChars(value: string, allowedChars?: string): string {
  if (!allowedChars) return value;
  let aux = '';
  for (let i=0; i < value.length; i++) {
    if (allowedChars.includes(value[i])) aux = `${aux}${value[i]}`;
  }
  return aux;
};

export function checkDeniedChars(value: string, deniedChars?: string): string {
  if (!deniedChars) return value;
  let aux = '';
  for (let i=0; i < value.length; i++) {
    if (!deniedChars.includes(value[i])) aux = `${aux}${value[i]}`;
  }
  return aux;
};

export function copyListOfObjects<Model>(list: Model[] = []): Model[] {
  const aux: Model[] = [];
  for (let i = 0; i < list.length; i++) {
    aux.push({ ...list[i] });    
  }
  return aux;
};

export const copyListOfArrays = (list = []) => {
  const aux = [];
  for (let i = 0; i < list.length; i++) {
    aux.push([ ...list[i] ]);    
  }
  return aux;
};

export function insertObjectOnList<Model>(list: Model[], newObj: Model): Model[] {
  const aux: Model[] = copyListOfObjects<Model>(list);
  aux.push({ ...newObj });
  return aux;
};

export const insertObjectOnListByIndex = (list: object[], newObj: object, index: number) => {
  const aux = [];
  let inserted = false;
  for (let i = 0; i < list.length; i++) {
    if (i === index) {
      inserted = true;
      aux.push({ ...newObj });
    }
    aux.push({ ...list[i] });
  }
  if (!inserted) { aux.push({ ...newObj }); }
  return aux;
};

export function updateObjectOnList<Model>(list: Model[], index: number, newObj: Model): Model[] {
  const aux: Model[] = [];
  for (let i = 0; i < list.length; i++) {
    if (i !== index) {
      aux.push({ ...list[i] });    
    } else {
      aux.push({ ...newObj });
    }
  }
  return aux;
};

export function removeObjectFromList<Model>(list: Model[], index: number): Model[] {
  const aux: Model[] = [];
  for (let i = 0; i < list.length; i++) {
    if (i !== index) {
      aux.push({ ...list[i] });    
    }
  }
  return aux;
};

export const removeItemFromList = (list: { _id: string }[], itemId: string) => {
  const aux = [];
  for (let i = 0; i < list.length; i++) {
    if (list[i]._id !== itemId) {
      aux.push({ ...list[i] });    
    }
  }
  return aux;
};

export function moveObjectOnList<Model>(list: Model[], index: number, move: number): Model[] {
  const aux: Model[] = [];
  const pos = index + move;
  if (pos < 0 || pos >= list.length) { return list; }
  for (let i = 0; i < list.length; i++) {
    if (i === pos) {
      aux.push({ ...list[index] });        
    } else if (i === index) {
      aux.push({ ...list[pos] });        
    } else {
      aux.push({ ...list[i] });        
    }
  }
  return aux;
};

export const findIndexById = (itemId: string, list: { _id: string }[]) => {
  for (let i = 0; i < list.length; i++) {
    if (list[i]._id === itemId) { return i; }
  }
  return -1;
};

export const findItemFromList = (itemId: string, list: { _id: string }[]) => {
  for (let i = 0; i < list.length; i++) {
    if (list[i]._id === itemId) { return { ...list[i] }; }
  }
  return {};
};

export const compareLists = (list1 = [], list2 = []) => {
  if (list1.length !== list2.length) { return 'NOT-EQUAL'; }
  for (let i = 0; i < list1.length; i++) {
    if (JSON.stringify(list1[i]) !== JSON.stringify(list2[i])) { return 'NOT-EQUAL'; }
  }
  return 'EQUAL';
};

import styled from 'styled-components';
import { IStyledProps } from './_styled_types';

import Box from './Box';

// ------

const Modal = styled(Box)<IStyledProps>`
  position: fixed;
  width: 100vw;
  height: 100vh;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  background-color: ${props => props.$bg || '#333333aa'};
  padding: ${props => props.$pd || '1rem'};
`;

export default Modal;
import React from 'react';

import Box from '../../components/Box';
import Span from '../../components/Span';

import { IController } from '../../page-home/controller/types';

//-----

interface IProps {
  ctl: IController;
}

//-----

export default function AddCategory({ ctl }: IProps): React.ReactElement {

  return (
    <Box $row $wd="100%" $pd="1rem">
      <Span $pd="0.4rem 1rem" $rad="2rem" $bg="black" $tc="white"
        onClick={ctl.categ.addItem}>
        AGREGAR CATEGORIA
      </Span>
    </Box>
  );

}

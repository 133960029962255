import React from 'react';

import * as AuthSRV from '../types/requests/auth.srv';

import Fetch from '../handlers/Fetch';
import { AppContext } from '../app-context/AppContext';

// ------

export interface ISignInController {
  loading: boolean;
}

const useController = (): ISignInController => {

  const ctx = React.useContext(AppContext);

  const [loading, setLoading] = React.useState<boolean>(false);

  const onSignIn = async () => {
    setLoading(true);
    const [ error, result ] = await Fetch.get<AuthSRV.SignInParams, AuthSRV.SignInResult>(AuthSRV.ENDPOINT.AuthSignIn, {});
    if (error) {
      // ctx.renderAlert({ title: error.warning, detail: error.message });
      if (error.code === 401) ctx.authenticate();
    } else {
      ctx.authenticate(result);
    }
    setLoading(false);
  };

  React.useEffect(() => {
    window.scrollTo(0, 0);
    onSignIn();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    loading
  };

};

export default useController;

import React from 'react';

import * as Store from '../../types/models/store';

import Box from '../../components/Box';

import EditTitle from './EditTitle';
import EditPrice from './EditPrice';
import EditSCode from './EditSCode';
import EditBCode from './EditBCode';
import EditDetail from './EditDetail';
import AllowedMode from './AllowedMode';
import EditComment from './EditComment';
import EditOptions from './EditOptions';
import EditMinimun from './EditMinimun';
import EditMaximun from './EditMaximun';
import EditCounter from './EditCounter';

// ------

interface IProps {
  product: Store.MenuProduct;
  onProduct: (p: Store.MenuProduct) => void;
  hasChange: (id: string, f: keyof Store.MenuProduct) => boolean;
}

// ------

export default function EditProduct(props: IProps): React.ReactElement {

  return (
    <Box $uwd="100%">

      <Box $row $uwd="100%" $hor="sb" $mg="0.5rem 0">
        <EditTitle {...props} />
        <EditPrice {...props} />
      </Box>

      <Box $row $uwd="100%" $hor="sb" $mg="0.5rem 0">
        <EditSCode {...props} />
        <EditBCode {...props} />
      </Box>
      
      <EditDetail {...props} />

      <AllowedMode {...props} />

      {
        props.product.allowOpts
        ? <Box $wd="100%" $pd="0.5rem" $rad="0.3rem 0 0.3rem 0.3rem" $bg="#ebe1ee">
            <EditComment {...props} />
            <EditOptions {...props} />
            <Box $row $uwd="100%" $hor="sa" $mg="0.5rem 0">
              <EditMinimun {...props} />
              <EditMaximun {...props} />
              <EditCounter {...props} />
            </Box>
          </Box>
        : null
      }

    </Box>
  );

}

import React from 'react';

import Button from '../components/Button';

// -------

interface IProps {
  showMe: boolean;
  loading?: boolean;
  onReset: () => void;
}

// -------

function ResetButton({ showMe, onReset }: IProps): React.ReactElement | null {

  if (!showMe) return null;

  return (
    <Button
      $xwd="280px"
      $fs="0.8rem"
      $bg="transparent"
      $tc="#ec25ec"
      $sha="none"
      onClick={onReset}
    >
      Modificar Email
    </Button>
  );

}

export default ResetButton;
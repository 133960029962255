import * as MenuAdmSRV from '../../types/requests/menu.adm.srv';
import * as Store from '../../types/models/store';

import { TAlertParams } from '../../components/Alert';
import Fetch from '../../handlers/Fetch';

// -------

export async function fetchStoreMenu(
  storeId: string,
  onAlert: (d: TAlertParams) => void,
  onMenu: (r: Store.Menu) => void
): Promise<void> {
  if (!storeId) return;
  const [ error, result ] = await Fetch.get<MenuAdmSRV.getStoreMenuParams, MenuAdmSRV.getStoreMenuResult>(
    MenuAdmSRV.ENDPOINT.getStoreMenu,
    { storeId }
  );
  if (error) {
    onAlert({ title: error.warning, detail: error.message });
  } else {
    if (result._id === storeId) onMenu(result.menu);
  }
}

// -------

export async function updateStoreMenu(
  storeId: string,
  menu: Store.Menu | null,
  onAlert: (d: TAlertParams) => void,
  onMenu: (r: Store.Menu) => void
): Promise<void> {
  if (!storeId || !menu) return;
  const [ error, result ] = await Fetch.post<MenuAdmSRV.postStoreMenuParams, MenuAdmSRV.postStoreMenuResult>(
    MenuAdmSRV.ENDPOINT.postStoreMenu,
    { storeId, menu }
  );
  if (error) {
    onAlert({ title: error.warning, detail: error.message });
  } else {
    if (result._id === storeId) onMenu(result.updatedMenu);
  }
}
import React from 'react';

import AppContextProvider from './app-context/AppContext';
import AppRouter from './app-router/AppRouter';

// ------

export default function App(): React.ReactElement {

  return (
    <AppContextProvider>
      <AppRouter />
    </AppContextProvider>
  );

}

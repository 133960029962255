

export const isEmail = (value: any) => {
  if (!value || typeof value !== 'string') return false;
  const validateEmailRegex = /^\S+@\S+\.\S+$/;
  return validateEmailRegex.test(value);
};


export function jsonCompare(obj1: any, obj2: any): boolean {
  return JSON.stringify(obj1) === JSON.stringify(obj2);
}
import React from 'react';

import * as AuthSRV from '../types/requests/auth.srv';

import Fetch from '../handlers/Fetch';
import { AppContext } from '../app-context/AppContext';

// ------

export interface ILoginController {
  email: string;
  setEmail: (v: string) => void;
  codeSent: boolean;
  setCodeSent: (v: boolean) => void;
  vcode: string;
  setVCode: (v: string) => void;
  onSignUp: () => void;
  onVerify: () => void;
  onReset: () => void;
  loading: boolean;
}

const useController = (): ILoginController => {

  const ctx = React.useContext(AppContext);

  const [email, setEmail] = React.useState<string>('');
  const [codeSent, setCodeSent] = React.useState<boolean>(false);
  const [vcode, setVCode] = React.useState<string>('');
  const [loading, setLoading] = React.useState<boolean>(false);

  const onSignUp = async () => {
    setLoading(true);
    const [ error, result ] = await Fetch.post<AuthSRV.SignUpParams, AuthSRV.SignUpResult>(AuthSRV.ENDPOINT.AuthSignUp, {
      email 
    });
    if (error) {
      ctx.renderAlert({ title: error.warning, detail: error.message });
    } else {
      setCodeSent(result.arrive && result.email === email);
    }
    setLoading(false);
  };

  const onVerify = async () => {
    setLoading(true);
    const [ error, result ] = await Fetch.post<AuthSRV.VerifyParams, AuthSRV.VerifyResult>(AuthSRV.ENDPOINT.AuthVerify, {
      email,
      vcode
    });
    if (error) {
      ctx.renderAlert({ title: error.warning, detail: error.message });
    } else {
      ctx.authenticate(result);
    }
    setLoading(false);
  };

  const onReset = () => {
    setCodeSent(false);
  };

  React.useEffect(() => {
    window.scrollTo(0, 0);
    if (codeSent) return;
    const el = document.getElementById('email-input') as HTMLInputElement;
    if (el) el.focus();
  }, [codeSent]);

  return {
    email,
    setEmail,
    codeSent,
    setCodeSent,
    vcode,
    setVCode,
    onSignUp,
    onVerify,
    onReset,
    loading
  };

};

export default useController;

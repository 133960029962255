import React from 'react';

import * as Store from '../../types/models/store';

import Box from '../../components/Box';
import Icons from '../../components/Icons';
import Span from '../../components/Span';

// ----

interface IProps {
  product: Store.MenuProduct;
  onProduct: (p: Store.MenuProduct) => void;
  hasChange: (id: string, f: keyof Store.MenuProduct) => boolean;
}

// ----

export default function AllowedMode(props: IProps): React.ReactElement {

  const { product, onProduct, hasChange } = props;

  const toggleObservations = () => {
    onProduct({ ...product, denyNotes: !product.denyNotes });
  };

  const toggleOptions = () => {
    onProduct({ ...product, allowOpts: !product.allowOpts });
  };

  const optColor = product.allowOpts ? '#ebe1ee' : undefined;

  const tc1 = hasChange(product._id, 'denyNotes') ? '#da7d0c' : undefined;
  const tc2 = hasChange(product._id, 'allowOpts') ? '#da7d0c' : undefined;

  return (
    <Box $row $uwd="100%" $hor="sb" $mgt="0.5rem">
      <Box $row $uwd="calc(50% - 0.5rem)" $pd="0.5rem" $rad="0.3rem"
        onClick={toggleObservations}>
        {
          product.denyNotes
          ? <Icons.EmptyBox />
          : <Icons.CheckBox />
        }
        <Span $mgl="0.2rem" $tc={tc1}>Permitir observaciones</Span>
      </Box>
      <Box $row $uwd="calc(50% - 0.5rem)" $pd="0.5rem"
        $rad="0.3rem 0.3rem 0 0" $bg={optColor}
        onClick={toggleOptions}>
        {
          !product.allowOpts
          ? <Icons.EmptyBox />
          : <Icons.CheckBox />
        }
        <Span $mgl="0.2rem" $tc={tc2}>Habilitar seleccionables</Span>
      </Box>
    </Box>
  );

}
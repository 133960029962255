import React from 'react';

import CircleButtons from './CircleButtons';
import { IController } from '../../page-home/controller/types';

// -----

interface IProps {
  prodId: string;
  ctl: IController;
}

// -----

export default function CloseProduct({ prodId, ctl }: IProps): React.ReactElement | null {

  if (prodId !== ctl.menu.editing) return null;

  return (

    <CircleButtons.Close
      $abs $tp="-0.5rem" $rh="-0.5rem"
      onClick={() => ctl.menu.setEdit(prodId)}
    />
      
  );

}

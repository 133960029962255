import React from 'react';

import Modal from './Modal';
import { H3 } from './Titles';
import Warning from './Warning';
import Button from './Button';
import Box from './Box';
import Space from './Space';

// -------

export type TAlertParams = {
  title?: string;
  detail?: string;
  callback?: () => void;
}

export interface IAlertProps extends TAlertParams {
  onClose: () => void;
}

// --------

export default function Alert(props: IAlertProps): React.ReactElement {

  const {
    title = 'ATENCION',
    detail = 'Oops, algo salió mal...',
    callback,
    onClose
  } = props;

  const handleClose = () => {
    if (callback) callback();
    onClose();
  };

  return (
    <Modal>
      <Box $wd="300px" $xwd="90%" $pd="1rem" $bg="white" $rad="0.4rem">
        <H3 $ta="center">{title}</H3>
        <Space />
        <Warning>{detail}</Warning>
        <Space />
        <Button onClick={handleClose}>Ok</Button>
      </Box>
    </Modal>
  );

}
import React from 'react';

import Box from '../../components/Box';
import CircleButtons from './CircleButtons';
import { IController } from '../../page-home/controller/types';

// -----

interface IProps {
  width: string;
  categId: string;
  prodId: string;
  ctl: IController;
}

// -----

export default function ProdButtons(props: IProps): React.ReactElement {

  const { width, categId, prodId, ctl } = props;

  return (

    <Box $wd={width}>

        <CircleButtons.MoveUp
          disabled={!ctl.prods.canMove(categId, prodId, -1)}
          onClick={() => ctl.prods.movePos(categId, prodId, -1)}
        />
        <CircleButtons.MoveDown $mg="0.2rem 0"
          disabled={!ctl.prods.canMove(categId, prodId, 1)}
          onClick={() => ctl.prods.movePos(categId, prodId, 1)}
        />
        <CircleButtons.Edit $mg="0.2rem 0"
          onClick={() => ctl.menu.setEdit(prodId)}
        />
        <CircleButtons.Remove
          onClick={() => ctl.prods.delItem(categId, prodId)}
        />

    </Box>

  );

}

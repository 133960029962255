import React from 'react';

import Button from '../components/Button';
import Warning from '../components/Warning';
import Spinner from '../components/Spinner';
import { isEmail } from '../utils/validators';

// -------

interface IProps {
  email: string;
  loading?: boolean;
  onSignUp: () => void;
}

// -------

function SignUpButton({ email, loading, onSignUp }: IProps): React.ReactElement {

  if (loading) return <Spinner $size="3rem" $mg="1rem 0" />;

  if (!isEmail(email)) return (
    <Warning $wd="100%" $xwd="280px">
      Ingresar dirección válida de email
    </Warning>
  );

  return (
    <Button $wd="100%" $xwd="280px" $mg="1rem 0" onClick={onSignUp}>
      Enviar código
    </Button>
  );

}

export default SignUpButton;
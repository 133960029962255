import * as Store from '../models/store';

// ------

export enum ENDPOINT {
  getStoresNames = '/menuadmsrv/stores/names',
  getStoreMenu   = '/menuadmsrv/store/menu',
  postStoreMenu  = '/menuadmsrv/store/menu',
  postMenuUser   = '/menuadmsrv/menu/user'
}

// ------

export type getStoresNamesParams = {};
export type getStoresNamesResult = { _id: string; name: string; published: boolean; }[];

// ------

export type getStoreMenuParams = { storeId: string; };
export type getStoreMenuResult = { _id: string; menu: Store.Menu; };

// ------

export type postStoreMenuParams = { storeId: string; menu: Store.Menu; };
export type postStoreMenuResult = { _id: string; updatedMenu: Store.Menu; };

// ------

export type postMenuUserParams = {
  email: string;
  phone: string;
  nombre: string;
  apellido: string;
  tipoDoc: string;
  numeroDoc: string;
  domicilio: string;
  ciudad: string;
  provincia: string;
  pais: string;
};
export type postMenuUserResult = { userId: string; };
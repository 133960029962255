import React from 'react';

import * as Store from '../../types/models/store';

import Box from '../../components/Box';
import Section from './Section';
import CategButtons from '../buttons/CategButtons';
import Products from '../Products';

import { IController } from '../../page-home/controller/types';

//-----

interface IProps {
  categ: Store.MenuCategory;
  ctl: IController;
}

//-----

export default function Category({ categ, ctl }: IProps): React.ReactElement {

  const categId = categ._id;

  return (
    <Box key={categId} id={categId} $uwd="100%" fadeIn>

      <Section
        edit={ctl.menu.editing === categId}
        onEdit={(v: boolean) => ctl.menu.setEdit(v ? categId : '', true)}
        title={categ.name}
        onTitle={(name: string) => ctl.categ.setItem({ ...categ, name })}
        titleChange={ctl.categ.changed(categId, 'name')}
      >

        <Products
          categId={categId}
          ctl={ctl}
        />

      </Section>

      <CategButtons
        categId={categId}
        ctl={ctl}
      />

    </Box>
  );


}

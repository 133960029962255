import React from 'react';

import Button from '../components/Button';
import Warning from '../components/Warning';
import Spinner from '../components/Spinner';

// -------

interface IProps {
  vcode: string;
  loading?: boolean;
  onVerify: () => void;
}

// -------

function VerifyButton({ vcode, loading, onVerify }: IProps): React.ReactElement {

  if (loading) return <Spinner $size="3rem" $mg="1rem 0" />;

  if (!vcode || vcode.length !== 6) return (
    <Warning $wd="100%" $xwd="280px">
      Ingresar código recibido por email
    </Warning>
  );

  return (
    <Button $wd="100%" $xwd="280px" $mg="1rem 0" onClick={onVerify}>
      Confirmar código
    </Button>
  );

}

export default VerifyButton;
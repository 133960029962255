import React from 'react';

import * as Store from '../../types/models/store';

import Box from '../../components/Box';
import Label from '../../components/Label';
import TextArea from '../../components/TextArea';

// -----

interface IProps {
  product: Store.MenuProduct;
  onProduct: (p: Store.MenuProduct) => void;
  hasChange: (id: string, f: keyof Store.MenuProduct) => boolean;
}

// -----

export default function EditComment(props: IProps): React.ReactElement | null {

  const { product, onProduct, hasChange } = props;

  if (!product.allowOpts) return null;

  const changeComment = (v: string) => {
    onProduct({ ...product, comment: v });
  };

  const bd = hasChange(product._id, 'comment') ? '2px solid orange' : undefined;

  return (

    <Box $wd="100%" $mg="0.5rem 0">
      <Label $wd="100%">ACLARACION (explicación sobre las opciones)</Label>
      <TextArea
        $bd={bd}
        placeholder="ej: Tenés que seleccionar minimo 2, maximo 4"
        value={product.comment}
        onChange={changeComment}
      />
    </Box>

  );

}
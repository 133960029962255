import { Profile } from '../models/user';

// ------------------------
// ** SERVER_COMMON_AUTH **
// ------------------------

export enum ENDPOINT {
  AuthSignUp = '/auth/signup',
  AuthVerify = '/auth/verify',
  AuthSignIn = '/auth/signin'
}


export type SignUpParams = {
  email: string;
}
export type SignUpResult = {
  email: string;
  arrive: boolean;
}


export type VerifyParams = {
  email: string;
  vcode: string;
}
export type VerifyResult = {
  email: string;
  token: string;
  profile: Profile;
  isAdmin?: boolean;
}


export type SignInParams = {} | undefined;
export type SignInResult = VerifyResult;


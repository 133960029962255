import React from 'react';

import Box from '../components/Box';
import EmailInput from './EmailInput';
import VCodeInput from './VCodeInput';
import Space from '../components/Space';
import SignUpButton from './SignUpButton';
import { ILoginController } from './useController';
import VerifyButton from './VerifyButton';
import ResetButton from './ResetButton';
import { H3 } from '../components/Titles';

// -------

interface IProps {
  controller: ILoginController;
}

// -------

function SignUpContent({ controller }: IProps): React.ReactElement {

  const {
    email,
    setEmail,
    codeSent,
    vcode,
    setVCode,
    onSignUp,
    onVerify,
    onReset,
    loading
  } = controller;

  return (
    <Box $mg="3rem 0" $pd="1rem" $rad="0.2rem" $bd="2px solid white">
      <H3 $ta="center">Iniciar Sesión</H3>
      <EmailInput
        showMe
        email={email}
        disabled={codeSent || loading}
        onEmail={setEmail}
        onEnter={onSignUp}
      />
      <ResetButton
        showMe={codeSent}
        onReset={onReset}
      />
      <Space $size={!codeSent ? '0' : '1rem'}/>
      <VCodeInput
        showMe={codeSent}
        vcode={vcode}
        disabled={false}
        onVCode={setVCode}
        onEnter={onVerify}
      />
      <Space />
      {
        !codeSent
        ? <SignUpButton
            email={email}
            loading={loading}
            onSignUp={onSignUp}
          />
        : <VerifyButton
            vcode={vcode}
            loading={loading}
            onVerify={onVerify}
          />
      }
    </Box>
  );

}

export default SignUpContent;
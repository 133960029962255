import React from 'react';

import Layout from '../components/Layout';
import Box from '../components/Box';
import Spinner from '../components/Spinner';
import useController from './useController';
import { H3 } from '../components/Titles';

// -------

function SignInPage(): React.ReactElement {

  const ctl = useController();

  return (
    <Layout
      header={<p>Hookau App</p>}
      content={(
        <Box $mg="3rem 0" $pd="1rem" $rad="0.2rem" $bd="2px solid white">
          <H3 $ta="center">Iniciando Sesión</H3>
          {
            ctl.loading && <Spinner $size="3rem" $mg="1rem 0" />
          }
        </Box>
      )}
    />
  );

}

export default SignInPage;
import React from 'react';

import Box from '../components/Box';
import Category from './category/Category';

import { IController } from '../page-home/controller/types';

//-----

interface IProps {
  ctl: IController;
}

//-----

export default function Categories({ ctl }: IProps): React.ReactElement {
 
  return (
    <Box $wd="100%">

      {
        ctl.menu.categories.map((category) => {

          const categId = category._id;

          return (
            <Category key={categId}
              categ={category}
              ctl={ctl}
            />
          );

        })
      }

    </Box>
  );

}

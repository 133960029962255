import React from 'react';

import Box from '../../components/Box';
import CircleButtons from './CircleButtons';

import { IController } from '../../page-home/controller/types';

// -----

interface IProps {
  categId: string;
  ctl: IController;
}

// -----

export default function CategButtons({ categId, ctl }: IProps): React.ReactElement {

  return (
  
    <Box $row $abs $wd="100%" $tp="-1rem">

      <CircleButtons.MoveUp
        $mg="0 0.5rem"
        disabled={!ctl.categ.canMove(categId, -1)}
        onClick={() => ctl.categ.movePos(categId, -1)}
      />

      <CircleButtons.MoveDown
        $mg="0 0.5rem"
        disabled={!ctl.categ.canMove(categId, 1)}
        onClick={() => ctl.categ.movePos(categId, 1)}
      />
    
      <CircleButtons.Remove
        $mg="0 0.5rem"
        disabled={!!categId && !!ctl.menu.products[categId] && ctl.menu.products[categId].length > 0}
        onClick={() => ctl.categ.delItem(categId)}
      />

    </Box>
  
  );

}


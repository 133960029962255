import React from 'react';

import Box from '../components/Box';
import StoreName from './StoreName';
import { IController } from './controller/types';

// -------

interface IProps {
  ctl: IController;
}

// -------

export default function SidebarStores({ ctl }: IProps): React.ReactElement {

  const storeId = ctl.stores.item?._id || '';

  return (
    <Box $wd="100%" $pd="1rem 0">
      {
        ctl.stores.list.map((store) => {
          return (
            <StoreName key={store._id}
              name={store.name}
              published={store.published}
              selected={ store._id === storeId}
              onSelect={() => ctl.stores.onSelect(store)}
            />
          );
        })
      }
    </Box>
  );

}
import React from 'react';
import { IStyledProps } from './_styled_types';

import Label from './Label';

// -------

interface IWarningProps extends IStyledProps {
  children: React.ReactNode;
}

// -------

export default function Warning(props: IWarningProps) {

  const { children, ...styledProps } = props;

  return (
    <Label $tc="#e44c31" $fw={500} $fs="0.8rem" $ta="center" {...styledProps}>
      {children}
    </Label>
  );

}


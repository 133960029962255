import * as Store from '../../types/models/store';

import {
  insertObjectOnList,
  moveObjectOnList,
  removeObjectFromList,
  updateObjectOnList
} from '../../utils/arrayHelpers';

import { randomId } from '../../utils/random';
import { StoreCompact, StoresView } from './types';

// -----

export const findIndexById = (id: string, list: { _id: string }[]): number => {
  let index = -1;
  for (let i=0; i < list.length; i++) {
    if (list[i]._id === id) return i;
  }
  return index;
};

export const canMoveItem = (id: string, inc: number, list: { _id: string }[]): boolean => {
  const index = findIndexById(id, list);
  const incIndex = index + inc;
  return incIndex >= 0 && incIndex < list.length;
};

// ----- STORES -----

export const filterStoresByView = (
  list: StoreCompact[],
  view: StoresView
): StoreCompact[] => {
  let filteredList: StoreCompact[] = [];
  list.forEach(item => {
    if (view === 'OUT') {
      if (!item.published) filteredList.push(item);
    } else if (view === 'PUB') {
      if (item.published) filteredList.push(item);
    } else {
      filteredList.push(item);
    }
  });
  return filteredList;
}

// ----- CATEGORIES -----

export const insertCategory = (categories: Store.MenuCategory[]): Store.MenuCategory[] => {
  return insertObjectOnList<Store.MenuCategory>(categories, { _id: randomId(), name: '' });
};

export const updateCategory = (
  categItem: Store.MenuCategory,
  categories: Store.MenuCategory[]
): Store.MenuCategory[] => {
  const index = findIndexById(categItem._id, categories);
  if (index < 0) return categories;
  return updateObjectOnList<Store.MenuCategory>(categories, index, categItem);
};

export const arrangeCategory = (
  categId: string,
  inc: number,
  categories: Store.MenuCategory[]
): Store.MenuCategory[] => {
  const index = findIndexById(categId, categories);
  if (index < 0) return categories;
  return moveObjectOnList<Store.MenuCategory>(categories, index, inc);
};

export const removeCategory = (
  categId: string,
  categories: Store.MenuCategory[]
): Store.MenuCategory[] => {
  const index = findIndexById(categId, categories);
  if (index < 0) return categories;
  return removeObjectFromList<Store.MenuCategory>(categories, index);
};

// ----- PRODUCTS -----

export const insertProduct = (products: Store.MenuProduct[]): Store.MenuProduct[] => {
  return insertObjectOnList<Store.MenuProduct>(products, {
    _id: randomId(),
    bcode: '',
    title: '',
    image: '',
    detail: '',
    options: '',
    price: 0,
    isPromo: false,
    minimun: 0,
    maximun: 0,
    counter: false,
    comment: '',
    denyNotes: false,
    short: ''
  });
};

export const updateProduct = (
  prodItem: Store.MenuProduct,
  products: Store.MenuProduct[]
): Store.MenuProduct[] => {
  const index = findIndexById(prodItem._id, products);
  if (index < 0) return products;
  return updateObjectOnList<Store.MenuProduct>(products, index, prodItem);
};

export const arrangeProduct = (
  prodId: string,
  inc: number,
  products: Store.MenuProduct[]
): Store.MenuProduct[] => {
  const index = findIndexById(prodId, products);
  if (index < 0) return products;
  return moveObjectOnList<Store.MenuProduct>(products, index, inc);
};

export const removeProduct = (
  prodId: string,
  products: Store.MenuProduct[]
): Store.MenuProduct[] => {
  const index = findIndexById(prodId, products);
  if (index < 0) return products;
  return removeObjectFromList<Store.MenuProduct>(products, index);
};









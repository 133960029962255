import React from 'react';

import * as Store from '../../types/models/store';

import Box from '../../components/Box';
import Input from '../../components/Input';
import Label from '../../components/Label';

// -----

interface IProps {
  product: Store.MenuProduct;
  onProduct: (p: Store.MenuProduct) => void;
  hasChange: (id: string, f: keyof Store.MenuProduct) => boolean;
}

// -----

export default function EditMinimun(props: IProps): React.ReactElement | null {

  const { product, onProduct, hasChange } = props;

  if (!product.allowOpts) return null;

  const changeMinimun = (v: string) => {
    onProduct({ ...product, minimun: Number(v) });
  };

  const bd = hasChange(product._id, 'minimun') ? '2px solid orange' : undefined;

  return (

    <Box $wd="30%">
      <Label $wd="100%">SELECCION MINIMA</Label>
      <Input
        $wd="100%" $ta="right" $bd={bd}
        placeholder="ej: 150"
        value={product.minimun.toString()}
        onChange={changeMinimun}
        allowedChars="0123456789"
        maxLenght={2}
      />
    </Box>

  );

}
import React from 'react';

import * as Store from '../../types/models/store';

import Box from '../../components/Box';
import Input from '../../components/Input';
import Label from '../../components/Label';

// -----

interface IProps {
  product: Store.MenuProduct;
  onProduct: (p: Store.MenuProduct) => void;
  hasChange: (id: string, f: keyof Store.MenuProduct) => boolean;
}

// -----

export default function EditPrice(props: IProps): React.ReactElement {

  const { product, onProduct, hasChange } = props;

  const handleChange = (v: string) => {
    onProduct({ ...product, price: Number(v) });
  };

  const bd = hasChange(product._id, 'price') ? '2px solid orange' : undefined;
  
  return (

    <Box $uwd="9rem">
      <Label $wd="100%">PRECIO</Label>
      <Input
        $wd="100%" $ta="right" $bd={bd}
        placeholder="ej: 150"
        value={product.price.toString()}
        onChange={handleChange}
        allowedChars="0123456789"
        maxLenght={6}
      />
    </Box>

  );

}

import React from 'react';
import styled from 'styled-components';

import * as Store from '../../types/models/store';

import Box from '../../components/Box';
import Button from '../../components/Button';

import { IController } from '../../page-home/controller/types';
import { convertFileToBase64 } from '../../utils/formatters';

// -----

interface IProps {
  width: string;
  categId: string;
  product: Store.MenuProduct;
  ctl: IController;
}

// -----

export default function ProdImage(props: IProps): React.ReactElement {

  const { width, categId, product, ctl } = props;
  const { _id, image = '' } = product;
  const prodId = _id;

  const handleImage = async (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files ? e.target.files[0] : null;
    if (!file) return;
    const image = await convertFileToBase64(file);
    if (!image) return;
    ctl.prods.setItem(categId, { ...product, image });
    e.target.files = null;
  };

  const openFileExplorer = () => {
    const el = document.getElementById(`${prodId}-file-input`) as HTMLInputElement;
    if (!el) return;
    el.click();
  };

  return (
    <Box $wd={width}>
      <HiddenInput
        id={`${prodId}-file-input`}
        accept=".jpg, .jpeg, .png, .svg"
        type="file"
        onChange={handleImage}
      />
      {
        image
        ? <FoodImage src={image} alt="Producto" />
        : <Box $cuad={width} $rad="0.3rem" $bg="#aaa" $fs="0.8rem">SIN FOTO</Box>
      }
      <Button $uwd={width} $rad="0.3rem" $mgt="0.2rem" onClick={openFileExplorer}>
        Imagen
      </Button>
    </Box>
  );

}


const HiddenInput = styled.input`
  display: none;
`;

const FoodImage = styled.img`
  width: 80px;
  min-width: 80px;
  max-width: 80px;
  height: 80px;
  min-height: 80px;
  max-height: 80px;
  object-fit: cover;
  border-radius: 0.3rem;
  border: 1px solid #ccc;
`;


import React from 'react';

import Box from '../components/Box';
import Label from '../components/Label';
import Input from '../components/Input';

// -------

interface IProps {
  email: string;
  onEmail: (s: string) => void;
}

// -------

function EmailInput(props: IProps): React.ReactElement {

  const { email, onEmail } = props;

  return (
    <Box $uwd="300px">
      <Label $uwd="100%">Email</Label>
      <Input
        $uwd="100%"
        type="text"
        value={email}
        onChange={onEmail}
        formatValue={(v) => (v.toLowerCase().trim())}
        selectOnFocus
      />
    </Box>
  );

}



export default EmailInput;
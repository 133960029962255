import React from 'react';

import Box from '../components/Box';
import Icons from '../components/Icons';
import Space from '../components/Space';

import { IController } from './controller/types';
import { IAppContext } from '../app-context/AppContext';

// -------

interface IProps {
  ctl: IController;
  ctx: IAppContext;
  onPage: (v: string) => void;
}

// -------

export default function HomeHeader({ ctl, ctx, onPage }: IProps): React.ReactElement {
  
  if (!ctl.stores.item || !ctl.menu.changed()) {
    return (
      <Box $row $wd="100%" $pd="0 1rem" $hor="sb">
        {
          ctx.userIsAdmin
          ? <Icons.Users
              $cuad="3rem"
              $tc="#ce6fdb"
              $fs="2rem"
              onClick={() => onPage('users')}
            />
          : <Space $size="3rem" />
        }
        <p>
          {
            !ctl.stores.item
            ? 'HOOKAU - MENU ADMIN'
            : ctl.stores.item.name
          }
        </p>
        <Icons.Logout
          $cuad="3rem"
          $tc="sienna"
          $fs="1.5rem"
          onClick={ctx.authenticate}
        />
      </Box>
    );
  };

  return (
    <Box $row $wd="100%" $pd="0 1rem" $hor="sb">
      <Icons.Cancel
        $cuad="3rem"
        $tc="tomato"
        $fs="1.5rem"
        onClick={ctl.menu.restore}
      />
      <p>
        {ctl.stores.item.name}
      </p>
      <Icons.Upload
        $cuad="3rem"
        $tc="#0a94d4"
        $fs="1.5rem"
        onClick={ctl.menu.saveMod}
      />
    </Box>
  );

}
import React from 'react';

import Box from '../components/Box';
import { IStyledProps } from '../components/_styled_types';

// -------

interface IProps {
  name: string;
  published: boolean;
  selected: boolean;
  onSelect: () => void;
}

// -------

export default function StoreName({ name, published, selected, onSelect }: IProps): React.ReactElement {

  const rowProps: IStyledProps = {
    $row: true,
    $wd: 'calc(100% - 1rem)',
    $hg: '2.2rem',
    $hor: 'lf',
    $ta: 'left',
    $pd: '0.4rem',
    $bg: !selected ? 'transparent' : '#d3e7f1',
    $tc: !published ? '#777' : '#1b5c6d',
    $nowrap: true,
    $ofw: 'hidden',
    $fs: '0.9rem'
  };


  return (
    <Box {...rowProps} onClick={!selected ? onSelect : undefined}>
      {name.length > 20 ? `${name.slice(0, 20)}...` : name}
    </Box>
  );

}
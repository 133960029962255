import React from 'react';
import styled, { css } from 'styled-components';
import { IStyledProps } from './_styled_types';

// ------

interface IBoxProps extends IStyledProps {
  className?: string;
  onClick?: () => void;
  children: React.ReactNode;
}

// ------

export default function Span(props: IBoxProps) {
  
  const { className, onClick, children, ...styledProps } = props;

  if (onClick) styledProps.$cur = 'pointer';

  const handleClick = (e: React.MouseEvent<HTMLSpanElement>) => {
    if (onClick) onClick();
  };

  return (
    <SpanStyled className={className} {...styledProps} onClick={handleClick}>
      {children}
    </SpanStyled>
  );

}

// ------

const SpanStyled = styled.span<IStyledProps>`
  ${props => props.$abs && css`position: absolute;`}
  ${props => props.$fix && css`position: fixed;`}
  ${props => props.$stk && css`position: sticky;`}
  
  ${props => props.$tp && css`top: ${props.$tp};`}
  ${props => props.$lf && css`left: ${props.$lf};`}
  ${props => props.$rh && css`right: ${props.$rh};`}
  ${props => props.$bt && css`bottom: ${props.$bt};`}

  ${props => props.$wd && css`width: ${props.$wd};`}
  ${props => props.$swd && css`min-width: ${props.$swd};`}
  ${props => props.$xwd && css`max-width: ${props.$xwd};`}
  ${props => props.$uwd && css`
    width: ${props.$uwd};
    min-width: ${props.$uwd};
    max-width: ${props.$uwd};
  `}

  ${props => props.$hg && css`height: ${props.$hg};`}
  ${props => props.$shg && css`min-height: ${props.$shg};`}
  ${props => props.$xhg && css`max-height: ${props.$xhg};`}
  ${props => props.$uhg && css`
    height: ${props.$uhg};
    min-height: ${props.$uhg};
    max-height: ${props.$uhg};
  `}

  ${props => (props.$cuad || props.$diam) && css`
    width: ${props.$cuad || props.$diam};
    min-width: ${props.$cuad || props.$diam};
    max-width: ${props.$cuad || props.$diam};
    height: ${props.$cuad || props.$diam};
    min-height: ${props.$cuad || props.$diam};
    max-height: ${props.$cuad || props.$diam};
    border-radius: ${props.$diam ? '50%' : '0'};
  `}

  ${props => props.$ta && css`text-align: ${props.$ta};`}
  ${props => props.$fs && css`
    font-size: ${props.$fs};
    line-height: ${props.$fs};
  `}
  ${props => props.$lh && css`line-height: ${props.$lh};`}
  ${props => props.$fw && css`font-weight: ${props.$fw};`}

  ${props => props.$pd && css`padding: ${props.$pd};`}
  ${props => props.$pdt && css`padding-top: ${props.$pdt};`}
  ${props => props.$pdb && css`padding-bottom: ${props.$pdb};`}
  ${props => props.$pdr && css`padding-right: ${props.$pdr};`}
  ${props => props.$pdl && css`padding-left: ${props.$pdl};`}

  ${props => props.$mg && css`margin: ${props.$mg};`}
  ${props => props.$mgt && css`margin-top: ${props.$mgt};`}
  ${props => props.$mgb && css`margin-bottom: ${props.$mgb};`}
  ${props => props.$mgr && css`margin-right: ${props.$mgr};`}
  ${props => props.$mgl && css`margin-left: ${props.$mgl};`}

  ${props => props.$rad && css`border-radius: ${props.$rad};`}

  ${props => props.$bd && css`border: ${props.$bd};`}
  ${props => props.$bdt && css`border-top: ${props.$bdt};`}
  ${props => props.$bdb && css`border-bottom: ${props.$bdb};`}
  ${props => props.$bdr && css`border-right: ${props.$bdr};`}
  ${props => props.$bdl && css`border-left: ${props.$bdl};`}

  ${props => props.$tc && css`color: ${props.$tc};`}
  ${props => props.$bg && css`background-color: ${props.$bg};`}
  ${props => props.$sha && css`box-shadow: ${props.$sha};`}

  ${props => props.$cur && css`cursor: ${props.$cur};`}
  ${props => props.$nowrap && css`white-space: nowrap;`}
  ${props => props.$ofw && css`overflow: ${props.$ofw};`}
`;

import React from 'react';
import styled from 'styled-components';
import { IStyledProps } from './_styled_types';

import { checkAllowedChars, checkDeniedChars } from '../utils/formatters';
import { randomId } from '../utils/random';

// -------

interface IProps extends IStyledProps {
  id?: string;
  rows?: number;
  value?: string;
  onEnter?: (v: string) => void;
  onChange?: (v: string) => void;
  formatValue?: (v: string) => string;
  placeholder?: string;
  className?: string;
  disabled?: boolean;
  allowedChars?: string;
  deniedChars?: string;
  maxLenght?: number;
  selectOnFocus?: boolean;
}

// -------

export default function TextArea(props: IProps) {

  const {
    id = randomId(),
    rows = 1,
    value = '',
    onEnter,
    onChange,
    formatValue,
    placeholder = '',
    className,
    disabled,
    allowedChars,
    deniedChars,
    maxLenght,
    selectOnFocus,
    ...styledProps
  } = props;

  const fixHeight = () => {
    const el = document.getElementById(id) as HTMLTextAreaElement;
    if (!el) return;
    el.style.height = '0px';
    el.style.height = `${el.scrollHeight + 4}px`;
  };

  const checkMaxLenght = (value: string): string => {
    if (maxLenght && value.length > maxLenght) {
      return value.slice(0, value.length - 1);
    }
    return value;
  }

  const handleChange = (e: React.ChangeEvent<HTMLTextAreaElement>): void => {
    e.preventDefault();
    const cursorPos = e.target.selectionStart;
    e.target.value = checkAllowedChars(e.target.value, allowedChars);
    e.target.value = checkDeniedChars(e.target.value, deniedChars);
    e.target.value = checkMaxLenght(e.target.value);
    if (formatValue) e.target.value = formatValue(e.target.value);
    if (onChange) onChange(e.target.value);
    e.target.selectionStart = cursorPos;
    e.target.selectionEnd = cursorPos;
    fixHeight();
  };

  const handleKeyDown = (e: React.KeyboardEvent<HTMLTextAreaElement>): void => {
    const keyName = e.code.toUpperCase();
    if (keyName === 'ESCAPE') {
      e.preventDefault();
      e.currentTarget.blur();
    }
    if (disabled) return;
    if (keyName === 'ENTER' && onEnter) {
      e.preventDefault();
      if (onChange) onChange(e.currentTarget.value as string);
      onEnter(e.currentTarget.value as string);
    }
  };

  const selectText = (e: React.FocusEvent<HTMLTextAreaElement>): void => {
    if (!selectOnFocus) return;
    e.currentTarget.select();
  };

  React.useEffect(() => {
    fixHeight();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <TextAreaStyled
      id={id}
      rows={rows}
      className={className}
      value={value}
      placeholder={placeholder}
      disabled={disabled}
      onChange={handleChange}
      onKeyDown={handleKeyDown}
      onFocus={selectText}
      {...styledProps}
    />
  );

}

// -------

const TextAreaStyled = styled.textarea<IStyledProps>`
  width: ${props => props.$wd || '100%'};
  text-align: ${props => props.$ta || 'left'};
  font-weight: ${props => props.$fw || 500};
  font-size: ${props => props.$fs || '0.9rem'};
  line-height: ${props => props.$lh || '1.2rem'};
  background-color: ${props => props.$bg || '#f5fafc'};
  color: ${props => props.$tc || '#333'};
  padding: ${props => props.$pd || '0.3rem 0.6rem'};
  margin: ${props => props.$mg || '0'};
  border-radius: ${props => props.$rad || '0.2rem'};
  border: ${props => props.$bd ? props.$bd : '2px solid #85b3e1'};
  outline: none;
  &:focus {
    outline: none;
    border: ${props => props.$bd ? props.$bd : '2px solid #3d89d4'};
    background-color: #e2f1fa;
  }
  &:disabled {
    outline: none;
    border: 2px solid #aaa;
    background-color: #ddd;
    color: #888;
  }
`;

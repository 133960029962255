import React from 'react';

import * as Store from '../../types/models/store';

import Box from '../../components/Box';
import Input from '../../components/Input';
import Label from '../../components/Label';

// -----

interface IProps {
  product: Store.MenuProduct;
  onProduct: (p: Store.MenuProduct) => void;
  hasChange: (id: string, f: keyof Store.MenuProduct) => boolean;
}

// -----

export default function EditSCode(props: IProps): React.ReactElement {
  
  const { product, onProduct, hasChange } = props;

  const changeShortCode = (v: string) => {
    onProduct({ ...product, short: v });
  };

  const bd = hasChange(product._id, 'short') ? '2px solid orange' : undefined;
  
  return (

    <Box $uwd="calc(50% - 0.5rem)">
      <Label $wd="100%">NOMBRE CORTO COMANDA</Label>
      <Input
        id="product-title"
        $wd="100%" $bd={bd}
        placeholder="ej: MILA COMP."
        value={product.short}
        formatValue={(value) => (value.toUpperCase())}
        onChange={changeShortCode}
      />
    </Box>

  );

}

import React from 'react';

import * as MenuAdmSRV from '../../types/requests/menu.adm.srv';
import * as Store from '../../types/models/store';

import { TRenderAlert } from '../../app-context/AppContext';

import { IController, StoreCompact, StoresView } from './types';
import { fadeInOut } from '../../utils/domHelpers';
import { jsonCompare } from '../../utils/validators';
import { fetchStoreMenu, updateStoreMenu } from './fetchers';
import {
  canMoveItem,
  insertCategory,
  removeCategory,
  updateCategory,
  arrangeCategory,
  insertProduct,
  removeProduct,
  updateProduct,
  arrangeProduct,
  findIndexById,
  filterStoresByView
} from './helpers';
import Fetch from '../../handlers/Fetch';

// ------

let bkpCategories: Store.MenuCategory[];
let bkpProducts: Store.MenuProducts;

// ------

const useController = (renderAlert: TRenderAlert): IController => {

  const [stores, setStores] = React.useState<StoreCompact[]>([]);
  const [store, setStore] = React.useState<StoreCompact | null>(null);
  const [view, setView] = React.useState<StoresView>('ALL');
  const [categories, setCategories] = React.useState<Store.MenuCategory[]>([]);
  const [products, setProducts] = React.useState<Store.MenuProducts>({});
  const [loading, setLoading] = React.useState<boolean>(false);
  const [editing, setEdit] = React.useState<string>('');

  const fetchStoresNames = async (): Promise<void> => {
    setLoading(true);
    const [ error, result ] = await Fetch.get<MenuAdmSRV.getStoresNamesParams, MenuAdmSRV.getStoresNamesResult>(
      MenuAdmSRV.ENDPOINT.getStoresNames, {}
    );
    if (error) {
      renderAlert({ title: error.warning, detail: error.message });
    } else {
      setStores(result);
    }
    setLoading(false);
  };

  const hasMenuChange = (): boolean => {
    return !jsonCompare(categories, bkpCategories) || !jsonCompare(products, bkpProducts);
  };

  const hasCategoryChange = (categId: string, field?: keyof Store.MenuCategory): boolean => {
    if (!categId) return false;
    const index = findIndexById(categId, categories);
    if (index < 0) return false;
    if (!field) return !jsonCompare(categories[index], bkpCategories[index]);
    return categories[index][field] !== bkpCategories[index][field];
  };

  const hasProductChange = (categId: string, prodId: string, field?: keyof Store.MenuProduct): boolean => {
    if (!categId || !prodId) return false;
    const index = findIndexById(prodId, products[categId] || []);
    if (index < 0) return false;
    if (!field) return !jsonCompare(products[categId][index], bkpProducts[categId][index]);
    return products[categId][index][field] !== bkpProducts[categId][index][field];
  };

  const resetMenu = (menu: Store.Menu) => {
    bkpCategories = menu && menu.categories ? [ ...menu.categories ] : [];
    bkpProducts = menu && menu.products ? { ...menu.products } : {};
    setCategories([ ...bkpCategories ]);
    setProducts({ ...bkpProducts });
  };

  const toggleView = (): void => {
    if (hasMenuChange()) return;
    let v: StoresView = 'ALL';
    if (view === 'ALL') v = 'PUB';
    if (view === 'PUB') v = 'OUT';
    setView(v);
    setStore(null);
  };

  const selectStore = (item: StoreCompact): void => {
    if (hasMenuChange()) return;
    setStore(item);
  };

  const downloadMenu = async (): Promise<void> => {
    if (!store) return;
    setLoading(true);
    await fetchStoreMenu(store._id, renderAlert, resetMenu);
    setLoading(false);
  };

  const restoreMenu = (): void => {
    setCategories([ ...bkpCategories ]);
    setProducts({ ...bkpProducts });
  };

  const uploadMenu = async (): Promise<void> => {
    if (!store) return;
    if (!hasMenuChange()) return;
    const menu = { categories, products };
    setLoading(true);
    await updateStoreMenu(store._id, menu, renderAlert, resetMenu);
    setLoading(false);
  };

  const handleEdit = (id: string, isCateg?: boolean) => {
    if (isCateg) {
      setEdit(id === editing ? '' : id);
      return;
    }
    fadeInOut(id, () => {
      setEdit(id === editing ? '' : id);
    });
  };

  // CATEGORIES

  const addCategory = (): void => {
    if (!categories) return;
    const newList = insertCategory(categories);
    const newCategId = newList[newList.length - 1]._id;
    setCategories(newList);
    setProducts({ ...products, [ newCategId ]: [] });
    setEdit(newCategId);
  };

  const delCategory = (categId: string): void => {
    if (!categories) return;
    fadeInOut(
      categId,
      () => {
        setCategories(removeCategory(categId, categories));
        setProducts({ ...products, [ categId ]: [] });
        setEdit('');
      }
    );
  };

  const setCategory = (categItem: Store.MenuCategory): void => {
    if (!categories) return;
    setCategories(updateCategory(categItem, categories));
  };

  const posCategory = (categId: string, inc: number): void => {
    if (!categories) return;
    fadeInOut(
      categId,
      () => {
        setCategories(arrangeCategory(categId, inc, categories));
        setEdit('');
      }
    );
  };

  const canMoveCateg = (categId: string, inc: number): boolean => {
    if (!categories) return false;
    return canMoveItem(categId, inc, categories);
  };

  // PRODUCTS

  const addProduct = (categId: string): void => {
    if (!products[categId]) return;
    const resultProds = insertProduct(products[categId]);
    setProducts({ ...products, [ categId ]: resultProds });
    const newProdId = resultProds[resultProds.length - 1]._id || '';
    setEdit(newProdId);
  };

  const delProduct = (categId: string, prodId: string): void => {
    if (!products[categId]) return;
    const resultProds = removeProduct(prodId, products[categId]);
    fadeInOut(
      prodId,
      () => {
        setProducts({ ...products, [ categId ]: resultProds });
        setEdit('');
      }
    );
  };

  const setProduct = (categId: string, prodItem: Store.MenuProduct): void => {
    if (!products[categId]) return;
    const resultProds = updateProduct(prodItem, products[categId]);
    setProducts({ ...products, [ categId ]: resultProds });
  };

  const posProduct = (categId: string, prodId: string, inc: number): void => {
    if (!products[categId]) return;
    const resultProds = arrangeProduct(prodId, inc, products[categId]);
    fadeInOut(
      prodId,
      () => {
        setProducts({ ...products, [ categId ]: resultProds });
        setEdit('');
      }
    );
  };

  const canMoveProd = (categId: string, prodId: string, inc: number): boolean => {
    if (!products[categId]) return false;
    return canMoveItem(prodId, inc, products[categId]);
  };

  React.useEffect(() => {
    window.scrollTo(0, 0);
    fetchStoresNames();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  React.useEffect(() => {
    if (!store) {
      setCategories([]);
      setProducts({});
      bkpCategories = [];
      bkpProducts = {};
      return;
    }
    downloadMenu();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ store ]);

  return {
    loading,
    stores: {
      list: filterStoresByView(stores, view),
      view,
      item: store,
      onView: toggleView,
      onSelect: selectStore
    },
    menu: {
      categories,
      products,
      changed: hasMenuChange,
      editing,
      setEdit: handleEdit,
      saveMod: uploadMenu,
      restore: restoreMenu
    },
    categ: {
      addItem: addCategory,
      delItem: delCategory,
      setItem: setCategory,
      movePos: posCategory,
      canMove: canMoveCateg,
      changed: hasCategoryChange
    },
    prods: {
      addItem: addProduct,
      delItem: delProduct,
      setItem: setProduct,
      movePos: posProduct,
      canMove: canMoveProd,
      changed: hasProductChange
    }
  };

};

export default useController;

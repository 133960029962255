import React from 'react';

import { IStyledProps } from './_styled_types';
import Box, { IBoxProps } from './Box';

// ------

interface IProps extends IStyledProps {
  children: string;

}

// ------

function Icon({ children, ...otherProps }: IProps): React.ReactElement {
  return (
    <Box {...otherProps}>
      <span className="material-symbols-outlined" style={{
        fontSize: 'inherit',
        lineHeight: 'inherit'
      }}>
        {children}
      </span>
    </Box>
  );
}

// ------

const cleanChildren = (props: IBoxProps) => {
  const { children, ...otherProps } = props;
  return otherProps;
}

const Icons = {
  Close: (props: IBoxProps) => (
    <Icon {...cleanChildren(props)}>close</Icon>
  ),
  Edit: (props: IBoxProps) => (
    <Icon {...cleanChildren(props)}>edit</Icon>
  ),
  Pencil: (props: IBoxProps) => (
    <Icon {...cleanChildren(props)}>stylus</Icon>
  ),
  Delete: (props: IBoxProps) => (
    <Icon {...cleanChildren(props)}>delete</Icon>
  ),
  ArrowUp: (props: IBoxProps) => (
    <Icon {...cleanChildren(props)}>arrow_upward_alt</Icon>
  ),
  ArrowDown: (props: IBoxProps) => (
    <Icon {...cleanChildren(props)}>arrow_downward_alt</Icon>
  ),
  SortArrows: (props: IBoxProps) => (
    <Icon {...cleanChildren(props)}>swap_vert</Icon>
  ),
  EmptyBox: (props: IBoxProps) => (
    <Icon {...cleanChildren(props)}>check_box_outline_blank</Icon>
  ),
  CheckBox: (props: IBoxProps) => (
    <Icon {...cleanChildren(props)}>select_check_box</Icon>
  ),
  Upload: (props: IBoxProps) => (
    <Icon {...cleanChildren(props)}>cloud_upload</Icon>
  ),
  Cancel: (props: IBoxProps) => (
    <Icon {...cleanChildren(props)}>cancel</Icon>
  ),
  Avatar: (props: IBoxProps) => (
    <Icon {...cleanChildren(props)}>account_circle</Icon>
  ),
  Home: (props: IBoxProps) => (
    <Icon {...cleanChildren(props)}>home</Icon>
  ),
  Logout: (props: IBoxProps) => (
    <Icon {...cleanChildren(props)}>logout</Icon>
  ),
  Users: (props: IBoxProps) => (
    <Icon {...cleanChildren(props)}>group_add</Icon>
  )
}


export default Icons;

import styled, { keyframes } from 'styled-components';

// ---------

const spin = keyframes`
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
`;

const Spinner = styled.span<{ $size?: string; $mg?: string; }>`
  width: ${props => props.$size || '4rem'};
  height: ${props => props.$size || '4rem'};
  margin: ${props => props.$mg || '2rem 0'};
  border: 6px solid #f3f3f3;
  border-top: 6px solid #3498db;
  border-radius: 50%;
  animation: ${spin} 2s linear infinite;
`;

export default Spinner;

import React from 'react';

import alertPortal, { TAlertParams } from '../components/Alert';
import * as AuthSRV from '../types/requests/auth.srv';
import * as User from '../types/models/user';

// -------

export type TAuthData = AuthSRV.SignInResult;

export type TRenderAlert = (D: TAlertParams) => void;

export interface IAppContext {
  hasAuthToken: boolean;
  userIsLogged: boolean;
  userIsAdmin: boolean;
  userProfile: User.Profile;
  authenticate: (d?: TAuthData) => void;
  renderAlert: TRenderAlert;
}

export const AppContext = React.createContext<IAppContext>({
  hasAuthToken: false,
  userIsLogged: false,
  userIsAdmin: false,
  userProfile: {} as User.Profile,
  authenticate: () => {},
  renderAlert: () => {}
});

const defaultAuthData: TAuthData = {
  email: '',
  token: '',
  profile: {} as User.Profile,
  isAdmin: false
};

// -------

export default function AppContextProvider({ children }: { children: React.ReactNode }) {

  const [authData, setAuthData] = React.useState<TAuthData>(defaultAuthData);
  const [alertParams, setAlertParams] = React.useState<TAlertParams | null>(null);

  const context = {
    hasAuthToken: !!authData.token,
    userIsLogged: !!authData.email && !!authData.token,
    userIsAdmin: !!authData.isAdmin,
    userProfile: authData.profile,
    authenticate: (data?: TAuthData) => {
      if (data) {
        console.log(data);
        setAuthData(data);
        localStorage.setItem('autk', data.token);
      } else {
        setAuthData(defaultAuthData);
        localStorage.setItem('autk', '');
      }
    },
    renderAlert: (params: TAlertParams) => setAlertParams(params)
  };

  React.useEffect(() => {
    setAuthData({
      ...authData,
      token: localStorage.getItem('autk') || ''
    });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <AppContext.Provider value={context}>
      {children}
      {
        alertParams
        ? alertPortal({
            ...alertParams,
            onClose: () => setAlertParams(null) 
          })
        : null
      }
    </AppContext.Provider>
  );

}

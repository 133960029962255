import React from 'react';

import Box from '../components/Box';
import Icons from '../components/Icons';
import Space from '../components/Space';

// -------

interface IProps {
  onPage: (v: string) => void;
}

// -------

export default function UsersHeader({ onPage }: IProps): React.ReactElement {
  
  return (
    <Box $row $wd="100%" $pd="0 1rem" $hor="sb">
      <Icons.Home
        $cuad="3rem"
        $tc="#333"
        $fs="2rem"
        onClick={() => onPage('')}
      />
      <p>HOOKAU - MENU ADMIN</p>
      <Space $size="3rem" />
    </Box>
  );

}
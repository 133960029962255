import React from 'react';

import * as Store from '../../types/models/store';

import Box from '../../components/Box';
import Input from '../../components/Input';
import Label from '../../components/Label';

// -----

interface IProps {
  product: Store.MenuProduct;
  onProduct: (p: Store.MenuProduct) => void;
  hasChange: (id: string, f: keyof Store.MenuProduct) => boolean;
}

// -----

export default function EditBCode(props: IProps): React.ReactElement {

  const { product, onProduct, hasChange } = props;

  const changeBarcode = (v: string) => {
    onProduct({ ...product, bcode: v });
  };

  const formatCode = (value: string) => {
    return value.trim().toUpperCase();
  };

  const bd = hasChange(product._id, 'bcode') ? '2px solid orange' : undefined;

  return (
    <Box $uwd="calc(50% - 0.5rem)">
      <Label $wd="100%">CODIGO DE PRODUCTO</Label>
      <Input
        $wd="100%" $ta="left" $bd={bd}
        placeholder="ej: 1FT6787_AA"
        value={product.bcode}
        formatValue={formatCode}
        onChange={changeBarcode}
      />
    </Box>
  );

}
import React from 'react';
import styled, { css } from 'styled-components';
import { IStyledProps } from './_styled_types';

// ------

interface IProps extends IStyledProps {
  onClick: () => void;
  disabled?: boolean;
  children: React.ReactNode;
  className?: string;
}

// ------

export default function Button(props: IProps) {

  const { onClick, disabled, children, className, ...styledProps } = props;

  const handleClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    e.stopPropagation();
    if (!disabled) onClick();
  };

  return (
    <ButtonStyled
      className={className}
      {...styledProps}
      disabled={disabled}
      onClick={handleClick}
    >
      {children}
    </ButtonStyled>
  );
};

// ------

const ButtonStyled = styled.button<IStyledProps>`
  position: ${props => props.$abs ? 'absolute' : 'relative'};
  
  ${props => props.$wd && css`width: ${props.$wd};`}
  ${props => props.$swd && css`min-width: ${props.$swd};`}
  ${props => props.$xwd && css`max-width: ${props.$xwd};`}
  ${props => props.$uwd && css`
    width: ${props.$uwd};
    min-width: ${props.$uwd};
    max-width: ${props.$uwd};
  `}

  ${props => props.$hg && css`height: ${props.$hg};`}
  ${props => props.$shg && css`min-height: ${props.$shg};`}
  ${props => props.$xhg && css`max-height: ${props.$xhg};`}
  ${props => props.$uhg && css`
    height: ${props.$uhg};
    min-height: ${props.$uhg};
    max-height: ${props.$uhg};
  `}

  ${props => (props.$cuad || props.$diam) && css`
    width: ${props.$cuad || props.$diam};
    min-width: ${props.$cuad || props.$diam};
    max-width: ${props.$cuad || props.$diam};
    height: ${props.$cuad || props.$diam};
    min-height: ${props.$cuad || props.$diam};
    max-height: ${props.$cuad || props.$diam};
    border-radius: ${props.$diam ? '50%' : '0'};
  `}

  text-align: ${props => props.$ta || 'center'};
  ${props => props.$fs && css`
    font-size: ${props.$fs};
    line-height: ${props.$fs};
  `}
  ${props => props.$lh && css`line-height: ${props.$lh};`}
  ${props => props.$fw && css`font-weight: ${props.$fw};`}
  padding: ${props => props.$pd || '0.3rem 0.6rem'};
  margin: ${props => props.$mg || '0'};

  ${props => props.$pd && css`padding: ${props.$pd};`}
  ${props => props.$pdt && css`padding-top: ${props.$pdt};`}
  ${props => props.$pdb && css`padding-bottom: ${props.$pdb};`}
  ${props => props.$pdr && css`padding-right: ${props.$pdr};`}
  ${props => props.$pdl && css`padding-left: ${props.$pdl};`}

  ${props => props.$mg && css`margin: ${props.$mg};`}
  ${props => props.$mgt && css`margin-top: ${props.$mgt};`}
  ${props => props.$mgb && css`margin-bottom: ${props.$mgb};`}
  ${props => props.$mgr && css`margin-right: ${props.$mgr};`}
  ${props => props.$mgl && css`margin-left: ${props.$mgl};`}

  border-radius: ${props => props.$rad || '0.2rem'};
  color: ${props => props.$tc || 'white'};
  background-color: ${props => props.$bg || '#3583b4'};
  box-shadow: ${props => props.$sha || '2px 2px 4px #00000055'};
  cursor: pointer;
  border: ${props => props.$bd || '2px solid transparent'};
  transition: border ease-in-out 0.2s;
  &:active {
    border: 2px solid #ffffff88;
    transition-duration: 0.05s;
  }
  &:disabled {
    background-color: #ddd;
    color: #777;
    box-shadow: none;
    cursor: default;
  }
`;


export enum ERRCODE {
  EnviromentError = 500,
  ServerError = 500,
  NothingFound = 404,
  BadParameters = 400,
  DataNotMatch = 403,
  NotAuthorized = 401
}

export enum WARNING {
  EnviromentError = 'ENVIROMENT_ERROR',
  ServerError = 'SERVER_ERROR',
  NothingFound = 'NOTHING_FOUND',
  BadParameters = 'BAD_PARAMETERS',
  DataNotMatch = 'DATA_NOT_MATCH',
  NotAuthorized = 'NOT_AUTHORIZED'
}

export enum MESSAGE {
  EnviromentError = 'Oops, also salió mal.',
  ServerError = 'Oops, also salió mal.',
  NothingFound = 'No se encontró nada aquí.',
  BadParameters = 'Parámetros inválidos.',
  DataNotMatch = 'Los datos no concuerdan.',
  NotAuthorized = 'Usuario sin permisos.'
}

export interface Error {
  code: ERRCODE | number;
  warning: WARNING | string;     // showable client alert
  message: MESSAGE | string;     // showable client alert
  reqUrl: string;
  reqBody?: object;
  reqQuery?: object;
  rawError?: any;
  timestamp: number;
  datestamp: string;
}

export interface Response<TResult> {
  serror?: Error;
  result?: TResult;
  error?: any;
}

import React from 'react';

import * as Store from '../../types/models/store';

import Box from '../../components/Box';
import TextArea from '../../components/TextArea';
import Label from '../../components/Label';

// -----

interface IProps {
  product: Store.MenuProduct;
  onProduct: (p: Store.MenuProduct) => void;
  hasChange: (id: string, f: keyof Store.MenuProduct) => boolean;
}

// -----

export default function EditOptions(props: IProps): React.ReactElement | null {

  const { product, onProduct, hasChange } = props;

  if (!product.allowOpts) return null;

  const handleChange = (v: string) => {
    onProduct({ ...product, options: v });
  };

  const bd = hasChange(product._id, 'options') ? '2px solid orange' : undefined;

  return (

    <Box $uwd="100%" $mg="0.5rem 0">

      <Label $wd="100%">SELECCIONABLES (separados con comas)</Label>
      <TextArea
        $fs="0.8rem" $lh="1.2rem" $bd={bd}
        placeholder="ej: coca, fanta, sprite, agua c/gas, agua s/gas, pomelo"
        value={product.options}
        onChange={handleChange}
      />

    </Box>

  );

}

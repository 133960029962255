import React from 'react';

import { H1 } from '../components/Titles';
import Box from '../components/Box';
import EditMenu from '../edit-menu/EditMenu';

import { IController } from './controller/types';

// -------

interface IProps {
  ctl: IController;
}

// -------

export default function HomeContent({ ctl }: IProps): React.ReactElement {

  if (!ctl.stores.item) {
    return (
      <Box $pd="10rem 0">
        <H1>Seleccionar Comercio</H1>
      </Box>
    );
  }

  return <EditMenu ctl={ctl} />;

}
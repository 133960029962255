import React from 'react';

import Layout from '../components/Layout';
import SignUpContent from './SignUpContent';
import useController from './useController';

// -------

function SignUpPage(): React.ReactElement {

  const controller = useController();

  return (
    <Layout
      header={<p>Hookau App</p>}
      content={<SignUpContent controller={controller} />}
    />
  );

}

export default SignUpPage;
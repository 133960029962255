import React from 'react';

import Input from '../../components/Input';
import Box from '../../components/Box';
import Icons from '../../components/Icons';
import { H4 } from '../../components/Titles';
import { IStyledProps } from '../../components/_styled_types';

// --------------

interface IProps extends IStyledProps {
  edit: boolean;
  onEdit: (v: boolean) => void;
  title: string;
  onTitle: (t: string) => void;
  children: React.ReactNode;
  titleChange: boolean;
}

const Section = (props: IProps) => {

  const {
    edit,
    onEdit,
    title,
    onTitle,
    children,
    titleChange,
    ...styled
  } = props;

  const wrapperProps: IStyledProps = {
    $uwd: '100%',
    $mgb: '3rem',
    $rad: '1.2rem',
    $sha: '3px 3px 6px #ccc',
    $bd: '2px solid #bd9aba',
    $fs: '1rem',
    ...styled
  };

  return (
    <Box {...wrapperProps}>
      <Box $row $wd="100%" $hor="sb" $pd="1rem" $rad="1rem 1rem 0 0"
        $bg={!titleChange ? '#bd9aba' : '#ffc762'}>
        {
          edit
          ? <Input
              $wd="100%" $xwd="400px"
              value={title}
              formatValue={value => value.toUpperCase()}
              onChange={onTitle}
            />
          : <H4 $mg="0" $wd="fit-content">
              {title}
            </H4>
        }
        {
          edit
          ? <Icons.Close $fs="1.2rem" onClick={() => onEdit(false)} />
          : <Icons.Edit $fs="1.2rem" onClick={() => onEdit(true)} />
        }
      </Box>
      <Box $wd="100%" $pd="1rem">
        {children}
      </Box>
    </Box>
  );

};

export default Section;

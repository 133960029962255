

// -------
/** for changes in components disappear and appears again */
/** also can be for remove elements disappear */
export function fadeInOut(id: string, callback: () => void, seconds = 0.3): void {
  let el = document.getElementById(id);
  if (!el) {
    callback();
    return;
  }
  el.style.transition = `opacity linear ${seconds}s`;
  el.style.opacity = '0';
  setTimeout(() => {
    callback();
    // el callback pudo haber eliminado el elemento
    // por trato de volver a obtenerlo
    el = document.getElementById(id); 
    if (el) el.style.opacity = '1';
  }, seconds * 1000);
}


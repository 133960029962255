import axios from 'axios';
import * as Serialized from '../types/requests/serialized';

// ------

type TFetchResponse<R> = [ Partial<Serialized.Error>, null ] | [ null, R ];

const serverError: Partial<Serialized.Error> = {
  code: Serialized.ERRCODE.ServerError,
  warning: Serialized.WARNING.ServerError,
  message: Serialized.MESSAGE.ServerError
};

// ------

class Fetch {

  private static getHeaders() {
    const autk = localStorage.getItem('autk') || '';
    return {
      'Content-Type': 'application/json',
      'X-Auth-Token': autk
    };
  }

  private static formatQuery(data = {}): string {
    let queryStr = '';
    const keys = Object.keys(data) as (keyof typeof data)[];
    keys.forEach((key, i) => {
      const value = data[key];
      queryStr += `${i === 0 ? '?' : '&'}${key}=${value}`
    });
    return queryStr;
  }

  private static handleResponse<R>(resp: Serialized.Response<R>): TFetchResponse<R> {
    console.log(resp);
    if (resp.result) return [ null, resp.result as R ];
    return [ resp.serror || serverError, null ];
  }

  // PUBLIC METHODS

  public static async get<P, R>(endpoint: string, query: P): Promise<TFetchResponse<R>> {
    try {
      const resp = await axios(`${endpoint}${this.formatQuery(query as object)}`, {
        method: 'GET',
        headers: this.getHeaders()
      });
      const serializedResp: Serialized.Response<R> = resp.data;
      console.log({ serializedResp });
      return this.handleResponse<R>(serializedResp);
    } catch(err) {
      console.log(err);
      return [ serverError, null ];
    }
  }

  public static async post<P, R>(endpoint: string, data: P): Promise<TFetchResponse<R>> {
    try {
      const resp = await axios(endpoint, {
        method: 'POST',
        data,
        headers: this.getHeaders()
      });
      const serializedResp: Serialized.Response<R> = resp.data;
      console.log({ serializedResp });
      return this.handleResponse<R>(serializedResp);
    } catch(error) {
      console.log({ error });
      return this.handleResponse<R>({ error });
    }
  }

  public static async put<P, R>(endpoint: string, data: P): Promise<TFetchResponse<R>> {
    try {
      const resp = await axios(endpoint, {
        method: 'PUT',
        data,
        headers: this.getHeaders()
      });
      const serializedResp: Serialized.Response<R> = resp.data;
      console.log({ serializedResp });
      return this.handleResponse<R>(serializedResp);
    } catch(error) {
      console.log({ error });
      return this.handleResponse<R>({ error });
    }
  }

  public static async delete<P, R>(endpoint: string, query: P): Promise<TFetchResponse<R>> {
    try {
      const resp = await axios(`${endpoint}${this.formatQuery(query as object)}`, {
        method: 'DELETE',
        headers: this.getHeaders()
      });
      const serializedResp: Serialized.Response<R> = resp.data;
      console.log({ serializedResp });
      return this.handleResponse<R>(serializedResp);
    } catch(error) {
      console.log({ error });
      return this.handleResponse<R>({ error });
    }
  }

}

export default Fetch;

import styled, { css } from 'styled-components';
import { IStyledProps } from './_styled_types';

// -------

const Label = styled.label<IStyledProps>`
  ${props => props.$wd && css`width: ${props.$wd};`}
  ${props => props.$swd && css`min-width: ${props.$swd};`}
  ${props => props.$xwd && css`max-width: ${props.$xwd};`}
  ${props => props.$uwd && css`
    width: ${props.$uwd};
    min-width: ${props.$uwd};
    max-width: ${props.$uwd};
  `}
  text-align: ${props => props.$ta || 'left'};
  padding: ${props => props.$pd || '0.2rem 0.4rem'};
  margin: ${props => props.$mg || '0'};
  font-weight: ${props => props.$fw || 500};
  font-size: ${props => props.$fs || '0.8rem'};
  color: ${props => props.$tc || '#777'};
  font-style: ${props => props.$italic ? 'italic' : 'normal'};
`;

export default Label;
import React from 'react';

import * as Store from '../../types/models/store';

import Box from '../../components/Box';
import ProdImage from './ProdImage';
import ProdData from './ProdData';
import ProdButtons from '../buttons/ProdButtons';

import { IController } from '../../page-home/controller/types';

// -----

interface IProps {
  categId: string;
  product: Store.MenuProduct;
  ctl: IController;
}

// -----

export default function Product(props: IProps): React.ReactElement {

  const { categId, product, ctl } = props;
  const prodId = product._id;

  return (

    <Box $row $wd="100%" $hor="sb" $ver="tp">
      <ProdImage
        width="80px"
        categId={categId}
        product={product}
        ctl={ctl}
      />
      <ProdData
        width="calc(100% - 130px)"
        categId={categId}
        product={product}
        ctl={ctl}
      />
      <ProdButtons
        width="30px"
        categId={categId}
        prodId={prodId}
        ctl={ctl}
      />
    </Box>

  );

}



import React from 'react';

import Icons from '../../components/Icons';
import { IBoxProps } from '../../components/Box';

// -----

const formatProps = (props: IBoxProps): IBoxProps => {
  return {
    $fs: '1.2rem',
    $lh: '1.2rem',
    $rad: '50%',
    $wd: '1.6rem',
    $swd: '1.6rem',
    $xwd: '1.6rem',
    $hg: '1.6rem',
    $shg: '1.6rem',
    $xhg: '1.6rem',
    $tc: props.disabled ? '#ddd' : 'white',
    $bg: props.disabled ? '#888' : '#222',
    ...props
  };
};

// -----

const Edit = (props: IBoxProps) => (
  <Icons.Edit {...formatProps(props)} />
);

const MoveUp = (props: IBoxProps): React.ReactElement => (
  <Icons.ArrowUp {...formatProps(props)} />
);

const MoveDown = (props: IBoxProps): React.ReactElement => (
  <Icons.ArrowDown {...formatProps(props)} />
);

const Remove = (props: IBoxProps): React.ReactElement => (
  <Icons.Delete {...formatProps(props)} />
);

const Close = (props: IBoxProps): React.ReactElement => (
  <Icons.Close {...formatProps(props)} />
);

// -----

const CircleButtons = {
  Edit,
  MoveUp,
  MoveDown,
  Remove,
  Close
};

export default CircleButtons;
import React from 'react';

import SignInPage from '../page-sign-in/SingInPage';
import SignUpPage from '../page-sign-up/SignUpPage';
import HomePage from '../page-home/HomePage';
import AccountsPage from '../page-users/UsersPage';

import { AppContext } from '../app-context/AppContext';

// ------

export default function AppRouter() {

  const [ page, setPage ] = React.useState<string>('');

  const ctx = React.useContext(AppContext);

  if (!ctx.userIsLogged) {
    if (ctx.hasAuthToken) return <SignInPage />;
    return <SignUpPage />;
  }

  if (page === 'users') return <AccountsPage onPage={setPage} />;

  return <HomePage onPage={setPage} />;

}
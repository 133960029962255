import React from 'react';
import styled from 'styled-components';

// ------

interface IProps {
  header: React.ReactElement;
  content: React.ReactElement;
  sidebar?: {
    head: React.ReactElement;
    body: React.ReactElement;
  }
}

// ------

export default function Layout({ header, content, sidebar }: IProps) {

  const headerHeight = '3rem';
  const contentHeight = `calc(100vh - ${headerHeight})`;
  const sidebarWidth = '200px';
  const contentWidth = !sidebar ? '100%' : `calc(100% - ${sidebarWidth})`;

  return (
    <>
      <Content $hg={contentHeight} $wd={contentWidth}>
        {content}
      </Content>
      {
        sidebar
          ? <>
              <SideBody $hg={contentHeight} $wd={sidebarWidth} className="scrollbar">
                {sidebar.body}
              </SideBody>
              <SideHead $hg={headerHeight} $wd={sidebarWidth}>
                {sidebar.head}
              </SideHead>
            </>
          : null
      }
      <Header $hg={headerHeight} $wd={contentWidth}>
        {header}
      </Header>
    </>
  );

}

// ------

const Header = styled.header<{ $hg: string; $wd: string; }>`
  position: fixed;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  top: 0;
  left: ${props => `calc(100% - ${props.$wd})`};
  width: ${props => props.$wd};
  min-width: ${props => props.$wd};
  max-width: ${props => props.$wd};
  height: ${props => props.$hg};
  min-height: ${props => props.$hg};
  max-height: ${props => props.$hg};
  background-color: white;
  box-shadow: 0px 2px 4px #aaa;
`;

// ------

const Content = styled.main<{ $hg: string; $wd: string; }>`
  position: fixed;
  display: flex;
  top: ${props => `calc(100vh - ${props.$hg})`};
  left: ${props => `calc(100% - ${props.$wd})`};
  width: ${props => props.$wd};
  min-width: ${props => props.$wd};
  max-width: ${props => props.$wd};
  height: ${props => props.$hg};
  min-height: ${props => props.$hg};
  max-height: ${props => props.$hg};
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  margin: 0;
  padding: 0.5rem;
  overflow-y: auto;
  overflow-x: hidden;
  scroll-behavior: smooth;
  background-color: #eee;
`;


// ------

const SideBody = styled.aside<{ $hg: string; $wd: string; }>`
  position: fixed;
  display: flex;
  top: ${props => `calc(100vh - ${props.$hg})`};
  left: 0;
  width: ${props => props.$wd};
  min-width: ${props => props.$wd};
  max-width: ${props => props.$wd};
  height: ${props => props.$hg};
  min-height: ${props => props.$hg};
  max-height: ${props => props.$hg};
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  overflow-y: auto;
  overflow-x: hidden;
  scroll-behavior: smooth;
  background-color: #b3cad0;
`;

// ------

const SideHead = styled.aside<{ $hg: string; $wd: string; }>`
  position: fixed;
  display: flex;
  top: 0;
  left: 0;
  width: ${props => props.$wd};
  min-width: ${props => props.$wd};
  max-width: ${props => props.$wd};
  height: ${props => props.$hg};
  min-height: ${props => props.$hg};
  max-height: ${props => props.$hg};
  flex-direction: row;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  color: white;
  background-color: #4c737d;
  box-shadow: 0px 2px 4px #aaa;
`;



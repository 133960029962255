import React from 'react';

import * as Store from '../../types/models/store';

import Box from '../../components/Box';
import Label from '../../components/Label';
import Span from '../../components/Span';
import Icons from '../../components/Icons';

// -----

interface IProps {
  product: Store.MenuProduct;
  onProduct: (p: Store.MenuProduct) => void;
  hasChange: (id: string, f: keyof Store.MenuProduct) => boolean;
}

// -----

export default function EditCounter(props: IProps): React.ReactElement | null {

  const { product, onProduct, hasChange } = props;

  if (!product.allowOpts) return null;

  const toggleCounter = () => {
    onProduct({ ...product, counter: !product.counter });
  };

  const tc = hasChange(product._id, 'counter') ? '#da7d0c' : undefined;

  return (

    <Box $wd="30%">
      <Label>SUMA CANTIDAD</Label>
      <Box $wd="100%" $row onClick={toggleCounter}>
        {
          product.counter
          ? <Icons.CheckBox />
          : <Icons.EmptyBox />
        }
        <Span $mgl="0.2rem" $tc={tc}>Repetitivos</Span>
      </Box>
    </Box>

  );

}
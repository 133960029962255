import React from 'react';

import Box from '../components/Box';
import Categories from './Categories';
import AddCategory from './buttons/AddCategory';

import { IController } from '../page-home/controller/types';
import Spinner from '../components/Spinner';

//-----

interface IProps {
  ctl: IController;
}

//-----

export default function EditMenu({ ctl }: IProps): React.ReactElement | null {

  if (!ctl.stores.item) return null;

  if (ctl.loading) return <Spinner />;

  return (
    <Box $wd="100%" $xwd="620px" $mg="4rem auto">

      <Categories ctl={ctl} />

      <AddCategory ctl={ctl} />

    </Box>
  );

}

import { IStyledProps } from './_styled_types';
import styled from 'styled-components';

// -------

export const H1 = styled.h1<IStyledProps>`
  width: ${props => props.$wd || '100%'};
  text-align: ${props => props.$ta || 'left'};
  padding: ${props => props.$pd || '0'};
  margin: ${props => props.$mg || '0'};
  color: ${props => props.$tc || '#333'};
  font-style: ${props => props.$italic ? 'italic' : 'normal'};
`;

export const H2 = styled.h2<IStyledProps>`
  width: ${props => props.$wd || '100%'};
  text-align: ${props => props.$ta || 'left'};
  padding: ${props => props.$pd || '0'};
  margin: ${props => props.$mg || '0'};
  color: ${props => props.$tc || '#333'};
  font-style: ${props => props.$italic ? 'italic' : 'normal'};
`;

export const H3 = styled.h3<IStyledProps>`
  width: ${props => props.$wd || '100%'};
  text-align: ${props => props.$ta || 'left'};
  padding: ${props => props.$pd || '0'};
  margin: ${props => props.$mg || '0'};
  color: ${props => props.$tc || '#333'};
  font-style: ${props => props.$italic ? 'italic' : 'normal'};
`;

export const H4 = styled.h4<IStyledProps>`
  width: ${props => props.$wd || '100%'};
  text-align: ${props => props.$ta || 'left'};
  padding: ${props => props.$pd || '0'};
  margin: ${props => props.$mg || '0'};
  color: ${props => props.$tc || '#333'};
  font-style: ${props => props.$italic ? 'italic' : 'normal'};
`;

export const H5 = styled.h5<IStyledProps>`
  width: ${props => props.$wd || '100%'};
  text-align: ${props => props.$ta || 'left'};
  padding: ${props => props.$pd || '0'};
  margin: ${props => props.$mg || '0'};
  color: ${props => props.$tc || '#333'};
  font-style: ${props => props.$italic ? 'italic' : 'normal'};
`;

export const H6 = styled.h6<IStyledProps>`
  width: ${props => props.$wd || '100%'};
  text-align: ${props => props.$ta || 'left'};
  padding: ${props => props.$pd || '0'};
  margin: ${props => props.$mg || '0'};
  color: ${props => props.$tc || '#333'};
  font-style: ${props => props.$italic ? 'italic' : 'normal'};
`;

import React from 'react';

import * as Store from '../types/models/store';

import Box from '../components/Box';
import Product from './product/Product';
import EditProduct from './product-form/EditProduct';
import AddProduct from './buttons/AddProduct';
import CloseProduct from './buttons/CloseProduct';

import { IStyledProps } from '../components/_styled_types';
import { IController } from '../page-home/controller/types';

// -----

interface IProps {
  categId: string;
  ctl: IController;
}

// -----

export default function Products({ categId, ctl }: IProps): React.ReactElement {

  const categProds = ctl.menu.products[categId] || [];

  const setProduct = (prodItem: Store.MenuProduct): void => {
    ctl.prods.setItem(categId, prodItem);
  };

  const hasChange = (prodId: string, field: keyof Store.MenuProduct) => {
    return ctl.prods.changed(categId, prodId, field);
  };

  let key: string = '';
  let prodId = '';

  const styledProdRow: IStyledProps = {
    $uwd: '100%',
    $mgb: '1rem',
    $pd: '0.5rem',
    $bg: 'white',
    $rad: '0.5rem',
    $sha: '2px 2px 6px #aaa',
  };

  return (

    <Box $wd="100%">

      {
        categProds.map((product) => {

          prodId = product._id;
          key = `${categId}-prodId`;

          return (
            <Box key={key} id={prodId} {...styledProdRow}
              $bd={ctl.prods.changed(categId, prodId) ? '2px solid orange' : 'none'}>

              {
                prodId === ctl.menu.editing
                ? <EditProduct
                    product={product}
                    onProduct={setProduct}
                    hasChange={hasChange}
                  />
                : <Product
                    categId={categId}
                    product={product}
                    ctl={ctl}
                  />
              }

              <CloseProduct prodId={prodId} ctl={ctl} />

            </Box>
          )

        })
      }

      <AddProduct categId={categId} ctl={ctl} />

    </Box>

  );

}

import React from 'react';

import * as Store from '../../types/models/store';

import Box from '../../components/Box';
import Label from '../../components/Label';
import TextArea from '../../components/TextArea';

// -----

interface IProps {
  product: Store.MenuProduct;
  onProduct: (p: Store.MenuProduct) => void;
  hasChange: (id: string, f: keyof Store.MenuProduct) => boolean;
}

// -----

export default function EditDetail(props: IProps): React.ReactElement {

  const { product, onProduct, hasChange } = props;

  const changeDetail = (v: string) => {
    onProduct({ ...product, detail: v });
  };

  const bd = hasChange(product._id, 'detail') ? '2px solid orange' : undefined;
  
  return (

    <Box $wd="100%" $mg="0.5rem 0">

      <Label $wd="100%">DESCRIPCION / INGREDIENTES</Label>
      <TextArea
        $bd={bd}
        placeholder="ej: Hamburguesa completa + papas + bebida"
        value={product.detail}
        onChange={changeDetail}
      />

    </Box>

  );

}


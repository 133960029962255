import React from 'react';

import Box from '../components/Box';
import Label from '../components/Label';
import Input from '../components/Input';

// -------

interface IProps {
  label: string;
  value: string;
  onValue: (s: string) => void;
}

// -------

export default function LabelInput(props: IProps): React.ReactElement {

  const { label, value, onValue } = props;

  return (
    <Box $uwd="300px" $mgt="0.5rem">
      <Label $uwd="100%">{label}</Label>
      <Input
        $uwd="100%"
        type="text"
        value={value}
        onChange={onValue}
      />
    </Box>
  );

}

import React from 'react';

import Layout from '../components/Layout';
import HomeHeader from './HomeHeader';
import HomeContent from './HomeContent';
import StoresFilter from './StoresFilter';
import SidebarStores from './SidebarStores';

import { AppContext } from '../app-context/AppContext';
import useController from './controller/useController';

// -------

interface IProps {
  onPage: (v: string) => void;
}

// -------

export default function HomePage({ onPage }: IProps): React.ReactElement {

  const ctx = React.useContext(AppContext);
  const ctl = useController(ctx.renderAlert);

  return (
    <Layout
      header={<HomeHeader ctl={ctl} ctx={ctx} onPage={onPage} />}
      content={<HomeContent ctl={ctl} />}
      sidebar={{
        head: <StoresFilter ctl={ctl} />,
        body: <SidebarStores ctl={ctl} />
      }}
    />
  );

}

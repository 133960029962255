import React from 'react';

import Box from '../components/Box';
import Icons from '../components/Icons';
import { IController } from './controller/types';


// -------

interface IProps {
  ctl: IController;
}

// -------

export default function StoresFilter({ ctl }: IProps): React.ReactElement {

  const { view } = ctl.stores;

  const text = {
    'ALL': 'Mostrar todos',
    'PUB': 'Publicados',
    'OUT': 'Sin publicar'
  };

  return (
    <Box $row $hor="lf" $wd="100%" $xwd="calc(100% - 1rem)" $nowrap $ofw="hidden" onClick={ctl.stores.onView}>
      <Icons.SortArrows $fs="1.2rem" $mg="0 0.5rem 0 0 " />
      <span>{text[view]}</span>
    </Box>
  );

}

import React from 'react';

import Layout from '../components/Layout';
import AccountsHeader from './UsersHeader';
import AccountsContent from './UsersContent';

import { AppContext } from '../app-context/AppContext';

// -------

interface IProps {
  onPage: (v: string) => void;
}

// -------

export default function UsersPage({ onPage }: IProps): React.ReactElement {

  const ctx = React.useContext(AppContext);

  return (
    <Layout
      header={<AccountsHeader onPage={onPage} />}
      content={<AccountsContent ctx={ctx} />}
    />
  );

}
